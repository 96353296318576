import './App.css';

function App() {
  const style = { 'width': "25%"  };
  return (
    <div className="App">
      <header className="App-header">
        <img style={style} src="/happyhivelogo.png" alt="logo" />
        <p>
          Happy Hive Childcare Centre Inc
        </p>

        <p>Our mission is to provide safe, nurturing childcare for the community of Kleefeld and the surrounding area.
          The focus of our centre is to promote an environment where children are able to learn, create, and grow in a fun, respectful, and inclusive setting.
          We strive to be community minded and dedicated to the families we serve.
        </p>
        <p>We are a group of community members working together to create a childcare centre in Kleefeld.
          If you have questions contact us at hello@happyhivecc.ca and&nbsp;
          <a href="https://www.facebook.com/happyhivechildcarecentre/">follow us on Facebook</a>.
        </p>
        <p>We are looking for sponsorships to help us cover startup costs.&nbsp;
        <a href="/SponsorshipPackage.pdf">For more information see our sponsorship package!</a></p>
      </header>
    </div>
  );
}

export default App;
